main {
  margin-top: 65px;
}

img {
  display: flex;
}

nav {
  background-color: rgba(242, 237, 237, 0.462);
  padding: 3px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}